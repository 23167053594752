<template>
  <c-card :title="`Detail Stok Opname : ${item.code}`" :loading="!item.id">
    <template slot="action">
      <c-button variant="outline-primary" :to="{name:'stockopnames'}">Kembali</c-button>
    </template>
    <b-row>
      <b-col sm="12" xl="6">
        <form-input :label-cols="3" :value="item.code" label="No. Transaksi" disabled></form-input>
        <form-date :label-cols="3" :value="item.date" label="Tanggal" disabled></form-date>
      </b-col>
      <b-col sm="12">
        <hr>
        <b-table-simple bordered table-class="text-center" class="mt-2 mb-2" responsive small outlined>
          <b-thead>
            <b-tr>
              <b-th rowspan="3">Merek</b-th>
              <b-th rowspan="3">Artikel</b-th>
              <b-th rowspan="3">Warna</b-th>
              <b-th rowspan="3">Harga</b-th>
              <b-th :colspan="(sizeRows.length||1)*2">Ukuran</b-th>
              <b-th rowspan="2" colspan="2">Jml</b-th>
              <b-th rowspan="3">Total</b-th>
            </b-tr>
            <b-tr>
              <b-th colspan="2" v-for="(v,k) in sizeRows" :key="k">{{v.size_name}}</b-th>
            </b-tr>
            <b-tr>
              <template v-for="(v,k) in sizeRows">
                <b-th :key="'aa'+k">Sistem</b-th>
                <b-th :key="'bb'+k">Sekarang</b-th>
              </template>
              <b-th>Sistem</b-th>
              <b-th>Sekarang</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(v,i) in item.stock_opname_details" :key="i">
              <b-td>{{v.brand_name}}</b-td>
              <b-td>{{v.item_code}}</b-td>
              <b-td>{{v.color_name}}</b-td>
              <b-td>{{v.price | formatNumber}}</b-td>
              <template v-for="(x,k) in sizeRows">
                <b-td :key="`a${k}`">
                  <template v-for="(s,l) in v.stock_opname_sizes">
                    <span v-if="x.id_size===s.id_size" :key="x.id_size+'~'+l">{{s.qty_system}}</span>
                  </template>
                </b-td>
                <b-td :key="`b${k}`">
                  <template v-for="(s,l) in v.stock_opname_sizes">
                    <span v-if="x.id_size===s.id_size" :key="x.id_size+'~'+l">{{s.qty_opname}}</span>
                  </template>
                </b-td>
              </template>
              <b-td>{{v.qty_system | formatNumber}}</b-td>
              <b-td>{{v.qty_opname | formatNumber}}</b-td>
              <b-td>Rp.{{v.price*v.qty_opname | formatNumber}}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col sm="12" xl="4">
        <form-textarea :label-cols="3" :value="item.note" label="Catatan" disabled></form-textarea>
      </b-col>
      <b-col sm="12" offset-xl="4" xl="4">
        <form-number :label-cols="4" :value="item.qty_system" label="Total Stok Sistem" disabled></form-number>
        <form-number :label-cols="4" :value="item.qty_opname" label="Total Stok Sekarang" disabled></form-number>
        <form-number :label-cols="4" :value="item.qty_different" label="Stok Selisih" disabled></form-number>
        <form-number :label-cols="4" :value="item.total_different" label="Total Selisih" disabled></form-number>
      </b-col>
    </b-row>
  </c-card>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import FormInput from '@/utils/components/FormInput.vue'
import FormDate from '@/utils/components/FormDate.vue'
import FormTextarea from '@/utils/components/FormTextarea.vue'
import FormNumber from '@/utils/components/FormNumber.vue'
import {BTableSimple,BThead,BTbody,BTr,BTh,BTd} from 'bootstrap-vue'
import flatMap from 'lodash/flatMap'
import findIndex from 'lodash/findIndex'
import sortBy from 'lodash/sortBy'
import CButton from '@/utils/components/CButton.vue'

export default {
  components: { CCard, FormInput, FormDate, FormTextarea, BTableSimple, BThead, BTbody, BTr, BTh, BTd, CButton, FormNumber },
  data(){
    return {
      item:{
        code:"",
        stock_opname_details:[]
      }
    }
  },
  computed:{
    sizeRows(){
      const dt = flatMap(this.item.stock_opname_details,n => n.stock_opname_sizes)
      let result = []
      for (let i = 0; i < dt.length; i++) {
        const el = dt[i];
        if(findIndex(result,{id_size: el.id_size})<0)
          result.push({
            id_size: el.id_size,
            size_name: el.size_name,
          })
      }
      return sortBy(result,['size_name'])
    },
  },
  methods:{
    async getData(){
      const {id} = this.$route.params
      const {data} = await this.$http.get(`v1/stock_opnames/show`,{params:{id}})
      this.item = data
    }
  },
  created(){
    this.getData()
  }
}
</script>

<style>

</style>