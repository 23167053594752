<template>
  <b-card no-body v-bind="attrs">
    <b-card-header style="d-flex flex-row" v-if="title||subTitle">
      <div>
        <b-card-title style="margin-bottom: 1.25rem">{{title}}</b-card-title>
        <b-card-sub-title style="margin-bottom: 1rem">{{subTitle}}</b-card-sub-title>
      </div>
      <div style="d-flex flex-row-col-2 justify-content-end">
        <slot name="action" />
      </div>
    </b-card-header>
    <b-card-body v-show="loading">
      <b-skeleton-wrapper spinner-variant="primary" :opacity="1" variant="secondary">
        <b-skeleton width="65%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="75%"></b-skeleton>
        <b-skeleton width="45%"></b-skeleton>
      </b-skeleton-wrapper>
    </b-card-body>
    <b-card-body v-show="!loading">
      <slot />
    </b-card-body>
  </b-card>
</template>

<script>
import {BCard,BCardHeader,BCardTitle,BCardBody,BCardSubTitle,BSkeletonWrapper,BSkeleton} from 'bootstrap-vue'
export default {
  name:'CCard',
  props:{
    title: String,
    subTitle: String,
    loading: {
      type: Boolean,
      default: false
    }
  },
  components:{
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BSkeletonWrapper,
    BSkeleton
  },
  computed:{
    attrs(){
      const attrs = JSON.parse(JSON.stringify(this.$attrs))
      delete attrs['no-body']
      return attrs
    }
  }
}
</script>

<style>

</style>